export type BuildUrlProps = Transformation & {
    cldId?: string;
    mediaType?: "image" | "video";
    type?: "upload" | "fetch";
    /**
     * Strips padding and pads to % of width
     */
    padPercent?: number;
};

export function buildUrl({
    cloudName,
    cldId,
    mediaType = "image",
    type = "upload",
    padPercent,
    ...transformation
}: BuildUrlProps & { cloudName: string }) {
    let transformationString = "";
    if (
        typeof padPercent === "number" &&
        transformation.width &&
        transformation.aspectRatio
    ) {
        const stepOne = transformationToString({ effect: "trim:1" });
        const stepTwo = transformationToString({
            crop: transformation.crop,
            aspectRatio: transformation.aspectRatio,
            width: Math.round(
                (Number(transformation.width) * padPercent) / 100,
            ),
        });
        const stepThree = transformationToString({
            ...transformation,
            crop: "lpad",
        });
        transformationString = [stepOne, stepTwo, stepThree].join("/");
    } else {
        transformationString = transformationToString(transformation);
    }
    if (transformationString !== "") transformationString += "/";
    return `https://res.cloudinary.com/${cloudName}/${mediaType}/${type}/${transformationString}${encodeURIComponent(
        `${cldId}`,
    )}`;
}

export function transformationToString(transformation: Transformation) {
    const result: string[] = [];
    if (transformation.fetchFormat) {
        result.push("f_" + transformation.fetchFormat);
    }
    if (transformation.quality) {
        result.push("q_" + transformation.quality);
    }
    if (transformation.width) {
        result.push("w_" + transformation.width);
    }
    if (transformation.height) {
        result.push("h_" + transformation.height);
    }
    if (transformation.crop) {
        result.push("c_" + transformation.crop);
    }
    if (transformation.gravity) {
        result.push("g_" + transformation.gravity);
    }
    if (transformation.radius) {
        result.push("r_" + transformation.radius);
    }
    if (transformation.angle) {
        result.push("a_" + transformation.angle);
    }
    if (transformation.effect) {
        result.push("e_" + transformation.effect);
    }
    if (transformation.opacity) {
        result.push("o_" + transformation.opacity);
    }
    if (transformation.border) {
        result.push("bo_" + transformation.border);
    }
    if (transformation.background) {
        if (transformation.background === "auto") {
            result.push("b_auto");
        }
        result.push("b_rgb:" + transformation.background.replace("#", ""));
    }
    if (transformation.defaultImage) {
        result.push("d_" + transformation.defaultImage);
    }
    if (transformation.zoom) {
        result.push("z_" + transformation.zoom);
    }
    if (transformation.aspectRatio) {
        result.push("ar_" + transformation.aspectRatio);
    }
    if (transformation.dpr) {
        result.push("dpr_" + transformation.dpr);
        if (transformation.color) {
            result.push("co_rgb_" + `${transformation.dpr}`.replace("#", ""));
        }
    }
    if (transformation.flags) {
        if (Array.isArray(transformation.flags)) {
            result.push("fl_" + transformation.flags.join("."));
        } else {
            result.push("fl_" + transformation.flags);
        }
    }
    return result.join();
}

/**
 * This string should only contain numbers. Doesn't actaully check.
 * Maybe someday Typescript supports regex or something.
 */
export type StringNumber = string | number;
export type FetchFormat = "auto" | string;
export type Quality =
    | "auto"
    | "auto:best"
    | "auto:good"
    | "auto:aco"
    | "auto:low"
    | "jpegmini"
    | "jpegmini:1"
    | "jpegmini:2"
    | StringNumber;
export type Crop =
    | "scale"
    | "fit"
    | "mfit"
    | "fill"
    | "lfill"
    | "limit"
    | "pad"
    | "lpad"
    | "mpad"
    | "crop"
    | "thumb"
    | "imagga_crop"
    | "imagga_scale";
export type Background = "auto" | string;
export type DPR = "auto" | StringNumber;

export interface Transformation {
    fetchFormat?: FetchFormat;
    quality?: Quality;
    crop?: Crop;
    gravity?: string;
    width?: StringNumber;
    height?: StringNumber;
    radius?: StringNumber;
    angle?: string;
    effect?: string;
    opacity?: StringNumber;
    border?: string;
    background?: Background;
    /**
     * Image must be in the root folder (aka id can't contain a slash) and must come with an extension (.jpg, .png, etc)
     */
    defaultImage?: string;
    zoom?: StringNumber;
    /**
     * Format of either 1.5 (implied 1.5:1) or 16:9
     */
    aspectRatio?: StringNumber;
    dpr?: DPR;
    color?: string;
    flags?: string | string[];
}

import { createContext, useContext, useEffect, useState } from "react";

const debugContext = createContext({ isDebug: false });

declare global {
    interface Window {
        turnTheKnob: () => void;
    }
}

export function DebugProvider({ children }: any) {
    const [isDebug, setIsDebug] = useState(false);
    useEffect(() => {
        window.turnTheKnob = () => setIsDebug(true);
    }, []);
    return <debugContext.Provider value={{ isDebug }} children={children} />;
}

export function useHideDebugContent() {
    const { isDebug } = useContext(debugContext);
    return !isDebug;
}

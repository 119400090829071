import { cloneElement, createContext, isValidElement, useContext } from "react";

import { BuildUrlProps, Transformation, buildUrl } from "./core";

type Configuration = {
    cloudName: string;
    defaultTransformation: Transformation;
    defaultVideoSourceTypes: string[];
};

const configContext = createContext<Configuration>({
    cloudName: "",
    defaultTransformation: {},
    defaultVideoSourceTypes: ["webm", "mp4", "ogv"],
});

/**
 * Provider (for global config)
 */
export function CloudinaryProvider({
    cloudName,
    defaultTransformation = {},
    defaultVideoSourceTypes = ["webm", "mp4", "ogv"],
    children,
}: ChildrenProp &
    Partial<Omit<Configuration, "cloudname">> &
    Pick<Configuration, "cloudName">) {
    return (
        <configContext.Provider
            value={{
                cloudName,
                defaultTransformation,
                defaultVideoSourceTypes,
            }}
        >
            {children}
        </configContext.Provider>
    );
}

/**
 * Hook (to get access to a buildUrl function with the global configuration)
 */
export function useBuildUrl(hookProps: BuildUrlProps = {}) {
    const { cloudName, defaultTransformation } = useContext(configContext);

    return function scopedBuildUrl(overrideProps: Partial<BuildUrlProps> = {}) {
        return buildUrl({
            ...defaultTransformation,
            ...hookProps,
            ...overrideProps,
            cloudName,
        });
    };
}

type ChildrenProp = {
    children: React.ReactNode;
};

/**
 * Image/Picture stuff
 */

export type CloudinaryImageProps = BuildUrlProps &
    ChildrenProp & {
        cldSrcSet?: number[];
    };

type ImageProps = React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>;

const pictureContext = createContext<Partial<BuildUrlProps>>({});

export function CloudinaryImage({
    cldSrcSet,
    children,
    ...transformationProps
}: CloudinaryImageProps) {
    if (!isValidElement(children)) {
        throw new Error(
            "CloudinaryImage must always be passed a single <img> as a child",
        );
    }
    const pictureTransformation = useContext(pictureContext);
    const buildUrl = useBuildUrl({
        ...pictureTransformation,
        ...transformationProps,
    });

    const cloneProps: Partial<ImageProps> = {
        src: buildUrl(),
        srcSet: cldSrcSet
            ? cldSrcSet
                  .map((width) => {
                      const url = buildUrl({ width: `${width}` });
                      return `${url} ${width}w`;
                  })
                  .join()
            : undefined,
    };

    return cloneElement(children, cloneProps);
}

export function CloudinaryPicture({
    children,
    ...buildUrlProps
}: BuildUrlProps & ChildrenProp) {
    return (
        <pictureContext.Provider value={buildUrlProps}>
            {children}
        </pictureContext.Provider>
    );
}

type SourceProps = React.DetailedHTMLProps<
    React.SourceHTMLAttributes<HTMLSourceElement>,
    HTMLSourceElement
>;

export function CloudinaryPictureSource({
    children,
    cldSrcSet,
    ...sourceTransformation
}: CloudinaryImageProps) {
    if (!isValidElement(children)) {
        throw new Error(
            "CloudinaryPictureSource must always be passed a single <source> as a child",
        );
    }

    const pictureTransformation = useContext(pictureContext);
    const buildUrl = useBuildUrl({
        ...pictureTransformation,
        ...sourceTransformation,
    });

    const src = buildUrl();
    const srcSet = cldSrcSet
        ? cldSrcSet
              .map((width) => {
                  const url = buildUrl({ width: `${width}` });
                  return `${url} ${width}w`;
              })
              .join()
        : undefined;

    const cloneProps: Partial<SourceProps> = {
        srcSet: srcSet ? srcSet + "," + src : src,
    };

    return cloneElement(children, cloneProps);
}

/**
 * Video
 */

type VideoProps = React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
>;

const videoContext = createContext<Partial<BuildUrlProps>>({});

export function CloudinaryVideo({
    children,
    poster = true,
    ...transformationProps
}: Omit<BuildUrlProps, "fetchFormat"> &
    ChildrenProp & { poster?: boolean | BuildUrlProps }) {
    if (!isValidElement(children)) {
        throw new Error(
            "CloudinaryVideo must always be passed a single <video> as a child",
        );
    }

    const buildUrl = useBuildUrl(transformationProps);

    const cloneProps: Partial<VideoProps> = {};
    // Only override the poster prop if asked to
    // If generating the image from the video's cldId, mediaType has to be video
    // But if a cldId is specified manually it's probably an image, so default to that
    if (!!poster)
        cloneProps.poster =
            typeof poster === "boolean"
                ? buildUrl({ mediaType: "video" })
                : buildUrl({
                      mediaType: poster.cldId ? "image" : "video",
                      ...poster,
                  });

    // Explicitly override fetchFormat because video formats and image formats don't mix
    return (
        <videoContext.Provider
            value={{ ...transformationProps, fetchFormat: undefined }}
        >
            {cloneElement(children, cloneProps)}
        </videoContext.Provider>
    );
}

export function CloudinaryVideoSource({
    children,
    fetchFormat,
    ...transformationProps
}: Omit<BuildUrlProps, "fetchFormat"> &
    Required<Pick<BuildUrlProps, "fetchFormat">> &
    ChildrenProp) {
    if (!isValidElement(children)) {
        throw new Error(
            "CloudinaryVideoSource must always be passed a single <source> as a child",
        );
    }

    const videoTransformationProps = useContext(videoContext);
    const buildUrl = useBuildUrl({
        ...videoTransformationProps,
        ...transformationProps,
        fetchFormat,
        mediaType: "video",
    });

    // Why is ogg the only one where the type differs from the extension?
    const cloneProps: Partial<SourceProps> = {
        src: buildUrl(),
    };
    if (fetchFormat !== "auto")
        cloneProps.type = `video/${
            fetchFormat === "ogv" ? "ogg" : fetchFormat
        }`;

    return cloneElement(children, cloneProps);
}

export function CloudinaryVideoSources({
    sourceTypes: overrideVideoSourceTypes,
}: {
    sourceTypes?: string[];
}) {
    const { defaultVideoSourceTypes } = useContext(configContext);
    const formats = overrideVideoSourceTypes ?? defaultVideoSourceTypes;

    return (
        <>
            {formats.map((format) => (
                <CloudinaryVideoSource key={format} fetchFormat={format}>
                    <source />
                </CloudinaryVideoSource>
            ))}
        </>
    );
}
